/**
 * @param {Function} fn 防抖函数
 * @param {Number} delay 延迟时间
 */
export function debounce(fn, delay) {
  var timer;
  return function () {
    var context = this;
    var args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
}

/**
 * @param {date} time 需要转换的时间
 * @param {String} fmt 需要转换的格式 如 yyyy-MM-dd、yyyy-MM-dd HH:mm:ss
 */
export function formatTime(time, fmt) {
  if (!time) return '';
  else {
    const date = new Date(time);
    const o = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'H+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds(),
      'q+': Math.floor((date.getMonth() + 3) / 3),
      S: date.getMilliseconds(),
    };
    if (/(y+)/.test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        (date.getFullYear() + '').substr(4 - RegExp.$1.length)
      );
    for (const k in o) {
      if (new RegExp('(' + k + ')').test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length === 1
            ? o[k]
            : ('00' + o[k]).substr(('' + o[k]).length)
        );
      }
    }
    return fmt;
  }
}

/**
 *
 * @param {缓存key} key
 * @param {需要存储的缓存值} value
 * @param {过期时间，默认0表示永久有效} expire 24 * 3600是一天
 */

// 设置缓存
export function setCache(key, value, expire = 24 * 3600) {
  console.log(value, 'value------------')
  //毫秒
  expire = (new Date()).getTime() + expire * 1000
  const storage = {
    v: value,
    exp: expire
  }

  try {
    localStorage.setItem(key, JSON.stringify(storage))
  } catch (e) {
    console.log(e)
  }
}

// 删除缓存
export function getCache(name) {
  try {
    const storage = localStorage.getItem(name)
    // console.log(storage, new Date().getTime(),'清除缓存删除成功---------8888888888',)
    if (storage.exp < (new Date().getTime())) {
      localStorage.removeItem(name)
      return
    } else {
      // console.log('999999999999999999没有可删除的缓存')
      return storage
    }
  } catch (e) {
    console.log(e)
  }

}
// 数组根据某个key从大到小排序
export function sortArrayByKey(arr, key) {
  return arr.sort((a, b) => b[key] - a[key]);
}